export interface FilterState {
  id?: string;
  viewportCoordinates: string;
  priceMax: string;
  sizeMin: string;
  roomsMin: string;
  availableFrom: string;
  petsAllowed: string;
  handledBy: string;
  propertyTypes: string;
  tags: string;
  upcomingProject: string;
  radius: string;
  page: string;
  zipCodes: string;
}

export const EMPTY_FILTER: FilterState = {
  viewportCoordinates: '',
  priceMax: '',
  sizeMin: '',
  roomsMin: '',
  availableFrom: '',
  petsAllowed: '',
  handledBy: '',
  propertyTypes: '[]',
  tags: '[]',
  upcomingProject: '',
  radius: '',
  page: '',
  zipCodes: '',
};
