import { format, parseISO } from 'date-fns';
import { GeneralCMSResult } from '../../types/cms/generalTypes';
import { Heading, DatePicker } from 'legacy-ui';
import { FiltersRow, FiltersRowHeader } from './styles';
import { FilterState } from './types';

interface AvailabilityFilterProps {
  generalTexts: GeneralCMSResult;
  internalState: FilterState;
  onChange: (value: string) => void;
}

const AvailabilityFilter = ({ generalTexts, internalState, onChange }: AvailabilityFilterProps) => {
  return (
    <FiltersRow>
      <FiltersRowHeader>
        <Heading tag="h3">{generalTexts.PropertyDetails.Availability}</Heading>
      </FiltersRowHeader>
      <DatePicker
        onFocus={(e) => e.target.blur()}
        placeholderText={generalTexts.Misc.SelectDate}
        minDate={new Date()}
        dateFormat="yyyy-MM-dd"
        selected={internalState.availableFrom ? parseISO(internalState.availableFrom) : undefined}
        isClearable
        onChange={(value) => {
          onChange(value ? format(value, 'yyyy-MM-dd') : '');
        }}
      />
    </FiltersRow>
  );
};

export default AvailabilityFilter;
