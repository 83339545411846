import { GeneralCMSResult } from '../../types/cms/generalTypes';
import { DIGIT_PATTERN } from '../../utils/constants';
import { CURRENCY_DKK } from '../../utils/currency';
import { Heading } from 'legacy-ui';
import { TextField } from 'legacy-ui';
import { FiltersRow, FiltersRowHeader } from './styles';
import { FilterState } from './types';

interface PriceFilterProps {
  generalTexts: GeneralCMSResult;
  internalState: FilterState;
  onChange: (value: string) => void;
}

const PriceFilter = ({ generalTexts, internalState, onChange }: PriceFilterProps) => {
  return (
    <FiltersRow>
      <FiltersRowHeader>
        <Heading tag="h3">
          {generalTexts.PropertyFinancials.Rent} ({generalTexts.Misc.Max})
        </Heading>
      </FiltersRowHeader>
      <TextField
        placeholder={`${generalTexts.Misc.Enter} ${generalTexts.PropertyFinancials.Rent.toLocaleLowerCase()}`}
        rightContent={CURRENCY_DKK}
        value={internalState?.priceMax}
        onChange={(event) => {
          const { value } = event.target;
          if (!DIGIT_PATTERN.test(value)) {
            return;
          }
          onChange(value);
        }}
        maxLength={8}
      />
    </FiltersRow>
  );
};

export default PriceFilter;
