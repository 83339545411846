import { GeneralCMSResult } from '../../types/cms/generalTypes';
import { DIGIT_PATTERN } from '../../utils/constants';
import { Heading } from 'legacy-ui';
import { TextField } from 'legacy-ui';
import { FiltersRow, FiltersRowHeader } from './styles';
import { FilterState } from './types';

interface SizeFilterProps {
  generalTexts: GeneralCMSResult;
  internalState: FilterState;
  onChange: (value: string) => void;
}

const SizeFilter = ({ generalTexts, internalState, onChange }: SizeFilterProps) => {
  return (
    <FiltersRow>
      <FiltersRowHeader>
        <Heading tag="h3">
          {generalTexts.PropertyDetails.Size} ({generalTexts.Misc.Min})
        </Heading>
      </FiltersRowHeader>
      <TextField
        placeholder={`${generalTexts.Misc.Enter} ${generalTexts.PropertyDetails.Size.toLocaleLowerCase()}`}
        rightContent="m²"
        value={internalState?.sizeMin}
        onChange={(event) => {
          const { value } = event.target;
          if (!DIGIT_PATTERN.test(value)) {
            return;
          }
          onChange(value);
        }}
        maxLength={3}
      />
    </FiltersRow>
  );
};

export default SizeFilter;
