import styled from 'styled-components';

export const FiltersWrapper = styled.div``;

export const FiltersHeader = styled.div`
  border-bottom: 1px solid ${(props) => props.theme.color.gray30};
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 12px;
  padding-bottom: 12px;
  p {
    color: ${(props) => props.theme.color.gray50};
    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }
`;

export const FiltersRow = styled.div`
  margin-top: 32px;
`;

export const FiltersRowHeader = styled.div`
  margin-bottom: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  p {
    color: ${(props) => props.theme.color.gray50};
    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }
`;

export const FiltersRowCheckboxes = styled.div`
  > div {
    margin-bottom: 8px;
    &:last-of-type {
      margin-bottom: 0;
    }
  }
`;

export const FiltersRowRadiusInput = styled.div`
  margin-top: 8px;
`;

export const FiltersButton = styled.div`
  position: relative;
`;

export const FiltersButtonPopover = styled.div`
  width: 300px;
  background: white;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  position: absolute;
  top: calc(100% + 8px);
  right: 0;
  z-index: 3;
`;

export const FiltersButtonPopoverFields = styled.div`
  max-height: 348px;
  overflow: auto;
  padding: 0 20px 32px 20px;
`;

export const FiltersButtonPopoverButtons = styled.div`
  border-top: 1px solid ${(props) => props.theme.color.gray30};
  padding: 20px;
  display: flex;
  > div {
    &:nth-child(1) {
      flex: 0 0 auto;
      margin-right: 12px;
    }
    &:nth-child(2) {
      flex: 1 1 auto;
    }
  }
`;

export const FiltersApplyButton = styled.div`
  margin-top: 32px;
`;
