import { GeneralCMSResult } from '../../types/cms/generalTypes';
import { PROPERTY_TYPE_APARTMENT, PROPERTY_TYPE_HOUSE, PROPERTY_TYPE_TOWNHOUSE } from '../../utils/constants';
import { Checkbox } from 'legacy-ui';
import { Heading } from 'legacy-ui';
import { FiltersRow, FiltersRowCheckboxes, FiltersRowHeader } from './styles';
import { FilterState } from './types';

interface PropertyTypeFilterProps {
  generalTexts: GeneralCMSResult;
  internalState: FilterState;
  onChange: (value: Partial<FilterState>) => void;
}

const PropertyTypeFilter = ({ generalTexts, internalState, onChange }: PropertyTypeFilterProps) => {
  return (
    <FiltersRow>
      <FiltersRowHeader>
        <Heading tag="h3">{generalTexts.PropertyDetails.Type}</Heading>
      </FiltersRowHeader>
      <FiltersRowCheckboxes>
        <Checkbox
          checked={!!internalState.propertyTypes && (JSON.parse(internalState.propertyTypes) as number[]).includes(PROPERTY_TYPE_APARTMENT)}
          onChange={() => {
            const propertyTypes: number[] = internalState.propertyTypes ? JSON.parse(internalState.propertyTypes) : [];
            const isChecked = propertyTypes.includes(PROPERTY_TYPE_APARTMENT);
            onChange({
              propertyTypes: JSON.stringify(
                isChecked ? propertyTypes.filter((type) => type !== PROPERTY_TYPE_APARTMENT) : [...propertyTypes, PROPERTY_TYPE_APARTMENT],
              ),
            });
          }}
        >
          {generalTexts.PropertyDetails.TypeApartment}
        </Checkbox>
        <Checkbox
          checked={!!internalState.propertyTypes && (JSON.parse(internalState.propertyTypes) as number[]).includes(PROPERTY_TYPE_TOWNHOUSE)}
          onChange={() => {
            const propertyTypes: number[] = internalState.propertyTypes ? JSON.parse(internalState.propertyTypes) : [];
            const isChecked = propertyTypes.includes(PROPERTY_TYPE_TOWNHOUSE);
            onChange({
              propertyTypes: JSON.stringify(
                isChecked ? propertyTypes.filter((type) => type !== PROPERTY_TYPE_TOWNHOUSE) : [...propertyTypes, PROPERTY_TYPE_TOWNHOUSE],
              ),
            });
          }}
        >
          {generalTexts.PropertyDetails.TypeTownhouse}
        </Checkbox>
        <Checkbox
          checked={!!internalState.propertyTypes && (JSON.parse(internalState.propertyTypes) as number[]).includes(PROPERTY_TYPE_HOUSE)}
          onChange={() => {
            const propertyTypes: number[] = internalState.propertyTypes ? JSON.parse(internalState.propertyTypes) : [];
            const isChecked = propertyTypes.includes(PROPERTY_TYPE_HOUSE);
            onChange({
              propertyTypes: JSON.stringify(
                isChecked ? propertyTypes.filter((type) => type !== PROPERTY_TYPE_HOUSE) : [...propertyTypes, PROPERTY_TYPE_HOUSE],
              ),
            });
          }}
        >
          {generalTexts.PropertyDetails.TypeHouse}
        </Checkbox>
      </FiltersRowCheckboxes>
    </FiltersRow>
  );
};

export default PropertyTypeFilter;
