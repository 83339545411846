import { useEffect, useState } from 'react';
import { GeneralCMSResult } from '../../types/cms/generalTypes';
import { DIGIT_PATTERN } from '../../utils/constants';
import { Heading } from 'legacy-ui';
import LocationSuggestionBox from '../LocationSuggestionBox';
import { getOptionFromSearchParams, getSearchParamsFromOption } from '../LocationSuggestionBox/functions';
import { Text } from 'legacy-ui';
import { TextField } from 'legacy-ui';
import { FiltersRow, FiltersRowHeader, FiltersRowRadiusInput } from './styles';
import { FilterState } from './types';

interface LocationFilterProps {
  generalTexts: GeneralCMSResult;
  internalState: FilterState;
  onChange: (value: Partial<FilterState>) => void;
}

const LocationFilter = ({ generalTexts, internalState, onChange }: LocationFilterProps) => {
  const [showRadius, setShowRadius] = useState(false);

  useEffect(() => {
    if (internalState.radius) {
      setShowRadius(true);
    }
  }, [internalState.radius]);

  return (
    <FiltersRow>
      <FiltersRowHeader>
        <Heading tag="h3">{generalTexts.Misc.Location}</Heading>
        <a
          onClick={() => {
            onChange({ radius: '' });
            setShowRadius(!showRadius);
          }}
        >
          <Text size="s">
            {showRadius ? generalTexts.Misc.Remove : generalTexts.Misc.Set} {generalTexts.Misc.Radius.toLowerCase()}
          </Text>
        </a>
      </FiltersRowHeader>
      <LocationSuggestionBox
        onChange={(option) => {
          const searchParamsFromOption = option ? getSearchParamsFromOption(option) : undefined;
          const zipCodes = searchParamsFromOption ? searchParamsFromOption.location?.zipCode : '';
          const viewportCoordinates = searchParamsFromOption ? JSON.stringify(searchParamsFromOption) : '';
          onChange({ viewportCoordinates, zipCodes });
        }}
        initialQuery={internalState.viewportCoordinates ? getOptionFromSearchParams(JSON.parse(internalState.viewportCoordinates)) : undefined}
        placeholder={generalTexts.Misc.SearchPlaceholder}
        loadingMessage={generalTexts.Misc.Loading}
        style="default"
        groupLabels={{
          city: generalTexts.Misc.City,
          municipality: generalTexts.Misc.Municipality,
          area: generalTexts.Misc.Area,
          property: generalTexts.Misc.PropertySingular,
        }}
      />
      {showRadius && (
        <FiltersRowRadiusInput>
          <TextField
            rightContent="KM"
            value={internalState.radius}
            onChange={(event) => {
              const { value } = event.target;
              if (!DIGIT_PATTERN.test(value)) {
                return;
              }
              onChange({ radius: value });
            }}
            maxLength={2}
            disabled={!internalState.viewportCoordinates}
          />
        </FiltersRowRadiusInput>
      )}
    </FiltersRow>
  );
};
export default LocationFilter;
