import styled from 'styled-components';

type BoxType = 'saveSearch' | 'addLocation' | 'searchExists' | 'searchSaved';

interface PopoverContainerProps {
  showPopover: boolean;
}

export const ModalTextWrapper = styled.div`
  margin-bottom: 48px;
  > div {
    font-size: 32px;
    font-weight: 600;
    margin-bottom: 24px;
  }
  @media only screen and (max-width: ${(props) => props.theme.breakpoint.md}) {
    > div {
      font-size: 24px;
    }
  }
`;

export const SavedSearchContainer = styled.div<{ boxType: BoxType }>`
  border-radius: 8px;
  padding: 24px;
  margin-bottom: 48px;

  @media only screen and (min-width: ${(props) => props.theme.breakpoint.lg}) {
    display: flex;
    justify-content: space-between;
  }

  color: ${(props) => {
    switch (props.boxType) {
      case 'saveSearch':
        return props.theme.color.saveSearchTextColor;
      case 'searchExists':
        return props.theme.color.saveSearchExistsTextColor;
      case 'searchSaved':
        return props.theme.color.saveSearchSavedTextColor;
    }
  }};

  background-color: ${(props) => {
    switch (props.boxType) {
      case 'saveSearch':
        return props.theme.color.saveSearchBackgroundColor;
      case 'addLocation':
        return props.theme.color.secondaryBackgroundColorActive;
      case 'searchExists':
        return props.theme.color.saveSearchExistsBackgroundColor;
      case 'searchSaved':
        return props.theme.color.saveSearchSavedBackgroundColor;
    }
  }};

  button {
    margin-top: 16px;
    @media only screen and (min-width: ${(props) => props.theme.breakpoint.lg}) {
      margin: auto 0;
    }
  }
`;

export const SearchDetailsBox = styled.div`
  border: 1px solid ${(props) => props.theme.color.gray30};
  border-radius: 8px;
  padding: 24px;
`;

export const EntryWrapper = styled.div`
  min-height: 252px;
  border: 1px solid ${(props) => props.theme.color.gray30};
  border-radius: 16px;
  padding: 40px 16px 16px 32px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  @media only screen and (max-width: ${(props) => props.theme.breakpoint.sm}) {
    flex-direction: column;
  }
`;

export const TextWrapper = styled.div`
  > p {
    margin-bottom: 16px;
    font-size: 20px;
  }
`;

export const PopoverContainer = styled.div<PopoverContainerProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 44px;
  height: 44px;
  border-radius: 50%;
  position: relative;
  cursor: pointer;
  background-color: ${(props) => (!props.showPopover ? 'none' : props.theme.color.secondaryBackgroundColorActive)};
  &:hover {
    background-color: ${(props) => props.theme.color.secondaryBackgroundColorActive};
  }
`;

export const PopoverWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ToogleSwitchWrapper = styled.div`
  display: flex;
  > div {
    flex-direction: row-reverse;
    align-items: center;
    > label:first-of-type {
      margin-left: 10px;
    }
    > label:not(:first-of-type) {
      margin-left: 0;
      font-weight: 600;
    }
    @media only screen and (max-width: ${(props) => props.theme.breakpoint.sm}) {
      > label:not(:first-of-type) {
        margin-bottom: 0;
      }
    }
  }
`;
