import { format, parseISO } from 'date-fns';
import { ParsedUrlQuery } from 'querystring';
import { SavedSearchAPIResult, SavedSearchLocationAPIResult } from '../../types/api/searchTypes';
import { GeneralCMSResult } from '../../types/cms/generalTypes';
import { removeEmptyValues, stringifyAllValues } from '../../utils/common';
import {
  PROPERTY_TYPE_APARTMENT,
  PROPERTY_TYPE_HOUSE,
  PROPERTY_TYPE_TOWNHOUSE,
  SENIOR_HOUSING_TAG,
  STUDENT_HOUSING_TAG,
} from '../../utils/constants';
import { formatAsCurrency } from '../../utils/currency';
import { EMPTY_FILTER, FilterState } from '../Filters/types';

function propertyTypesToDescription(propertyTypes: string, generalTexts: GeneralCMSResult) {
  return JSON.parse(propertyTypes)
    .map((propertyType: string) => {
      switch (parseInt(propertyType)) {
        case PROPERTY_TYPE_APARTMENT:
          return generalTexts.PropertyDetails.TypeApartment;
        case PROPERTY_TYPE_TOWNHOUSE:
          return generalTexts.PropertyDetails.TypeTownhouse;
        case PROPERTY_TYPE_HOUSE:
          return generalTexts.PropertyDetails.TypeHouse;
      }
    })
    .join(', ');
}

function tagsToDescription(tags: string, generalTexts: GeneralCMSResult) {
  return JSON.parse(tags)
    .map((tag: string) => {
      switch (tag) {
        case SENIOR_HOUSING_TAG:
          return generalTexts.PropertyDetails.SeniorHousing;
        case STUDENT_HOUSING_TAG:
          return generalTexts.PropertyDetails.StudentHousing;
      }
    })
    .join(', ');
}

export function getSavedSearchDetailsText(query: ParsedUrlQuery, generalTexts: GeneralCMSResult, unit: string | undefined) {
  const { priceMax, sizeMin, roomsMin, availableFrom, handledBy, petsAllowed, propertyTypes, tags, upcomingProject } = query;
  const priceMaxText = `${priceMax ? generalTexts.Misc.Max + ' ' + formatAsCurrency(Number(priceMax), unit) : ''}`;
  const propertyTypesText = propertyTypes ? propertyTypesToDescription(propertyTypes as string, generalTexts) : '';
  const sizeMinText = `${sizeMin ? generalTexts.Misc.AtLeast + ' ' + sizeMin + 'm²' : ''}`;
  const roomsMinText = `${
    roomsMin
      ? generalTexts.Misc.AtLeast +
        ' ' +
        roomsMin +
        ' ' +
        (roomsMin === '1' ? generalTexts.PropertyDetails.RoomSingular.toLowerCase() : generalTexts.PropertyDetails.RoomPlural.toLowerCase())
      : ''
  }`;
  const availableFromText = `${
    availableFrom ? generalTexts.PropertyDetails.Availability + ': ' + format(parseISO(availableFrom as string), 'dd-MM-yyyy') : ''
  }`;
  const tagsText = tags ? tagsToDescription(tags as string, generalTexts) : '';
  const handledByText = `${handledBy === 'propstep' ? generalTexts.Misc.ManagedBy + ' Propstep' : ''}`;
  const upcomingProjectText = `${upcomingProject === 'true' ? generalTexts.Misc.Upcoming : ''}`;
  const petsAllowedText = `${petsAllowed === 'true' ? generalTexts.PropertyDetails.PetsAllowed : ''}`;
  return [
    priceMaxText,
    propertyTypesText,
    sizeMinText,
    roomsMinText,
    availableFromText,
    tagsText,
    handledByText,
    upcomingProjectText,
    petsAllowedText,
  ]
    .filter(Boolean)
    .join(', ');
}

function getLocationLabelForSavedSearch(location: SavedSearchLocationAPIResult): string {
  const [locationSavedSearch] = location.city ? location.name.split(',') : [location.name];
  return locationSavedSearch;
}

function formatAsIntegerToCompareQuery(value: string | number | string[] | undefined, unit: string | string[] | undefined) {
  if ((typeof value === 'string' || typeof value === 'number') && unit === 'cents') {
    return Math.round(Number(value) / 100);
  }
  return value;
}

function getRelevantPropertiesFromObject(value: ParsedUrlQuery | SavedSearchAPIResult) {
  const { location, priceMax, propertyTypes, sizeMin, roomsMin, availableFrom, tags, handledBy, upcomingProject, petsAllowed, unit } = value;
  const locationLabel = location
    ? getLocationLabelForSavedSearch(location as SavedSearchLocationAPIResult)
    : JSON.parse((value as ParsedUrlQuery).viewportCoordinates as string).location.label;
  return {
    location: locationLabel,
    priceMax: formatAsIntegerToCompareQuery(priceMax, unit),
    propertyTypes,
    sizeMin,
    roomsMin,
    availableFrom: availableFrom?.slice(0, 10),
    tags,
    handledBy,
    upcomingProject,
    petsAllowed,
  };
}

export function queryIsInSavedSearches(query: ParsedUrlQuery, savedSearches: SavedSearchAPIResult[]) {
  const queryString = JSON.stringify(stringifyAllValues(removeEmptyValues(getRelevantPropertiesFromObject(query))));
  return savedSearches.some((savedSearch) => {
    const { unit, ...rest } = savedSearch;
    const savedSearchWithoutUnit = rest;
    if (savedSearchWithoutUnit.priceMax && unit === 'cents') {
      savedSearch.priceMax = Math.round(savedSearch.priceMax / 100);
    }
    return JSON.stringify(stringifyAllValues(removeEmptyValues(getRelevantPropertiesFromObject(savedSearchWithoutUnit)))) === queryString;
  });
}

function formatAsIntegerCreateFilterState(value: number | undefined, unit: string | undefined): string {
  if (value == null) {
    return '';
  }

  //part of the decimal migration process
  if (unit === 'cents') {
    return Math.trunc(value / 100).toString();
  }

  return value.toString();
}

export function createFilterStateFromSavedSearch(savedSearch: SavedSearchAPIResult): FilterState {
  if (!savedSearch) {
    return EMPTY_FILTER;
  }
  return {
    id: savedSearch.id,
    priceMax: formatAsIntegerCreateFilterState(savedSearch.priceMax, savedSearch.unit),
    roomsMin: savedSearch.roomsMin?.toString() || '',
    sizeMin: savedSearch.sizeMin?.toString() || '',
    availableFrom: savedSearch.availableFrom,
    handledBy: savedSearch.handledBy,
    upcomingProject: savedSearch.upcomingProject?.toString() || 'false',
    petsAllowed: savedSearch.petsAllowed?.toString() || 'false',
    propertyTypes: (savedSearch.propertyTypes && JSON.stringify(savedSearch.propertyTypes)) || '[]',
    tags: (savedSearch.tags && JSON.stringify(savedSearch.tags)) || '[]',
    page: '1',
    zipCodes: savedSearch.location.postalcode,
    viewportCoordinates: JSON.stringify({
      ...savedSearch.location.vc,
      location: {
        label: savedSearch.location.name,
        city: savedSearch.location.city,
        zipCode: savedSearch.location.postalcode,
      },
    }),
    radius: savedSearch.radius?.toString(),
  };
}

export function createSavedSearchFromFilterState(filterState: FilterState): Partial<SavedSearchAPIResult> {
  const { propertyTypes, tags, viewportCoordinates, ...restOfQuery } = filterState;
  const { location, ...vc } = JSON.parse(viewportCoordinates);

  return {
    id: filterState.id,
    location: {
      vc,
      country: 'DK',
      name: location?.label,
      city: location?.city,
      postalcode: location?.postalcode,
    },
    ...restOfQuery,
    radius: Number(restOfQuery.radius),
    priceMax: Number(restOfQuery.priceMax),
    roomsMin: Number(restOfQuery.roomsMin),
    sizeMin: Number(restOfQuery.sizeMin),
    upcomingProject: restOfQuery.upcomingProject === 'true',
    petsAllowed: filterState.petsAllowed === 'true',
    ...(propertyTypes ? { propertyTypes: JSON.parse(propertyTypes as string) } : {}),
    ...(tags ? { tags: JSON.parse(tags as string) } : {}),
  };
}
