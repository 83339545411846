import { GeneralCMSResult } from '../../types/cms/generalTypes';
import { getIntegerRange } from '../../utils/common';
import { Heading } from 'legacy-ui';
import { Select } from 'legacy-ui';
import { FiltersRow, FiltersRowHeader } from './styles';
import { FilterState } from './types';

const MAX_ROOMS = 5;

interface RoomsFilterProps {
  generalTexts: GeneralCMSResult;
  internalState: FilterState;
  onChange: (value: string) => void;
}

const RoomsFilter = ({ generalTexts, internalState, onChange }: RoomsFilterProps) => {
  return (
    <FiltersRow>
      <FiltersRowHeader>
        <Heading tag="h3">{generalTexts.PropertyDetails.RoomPlural}</Heading>
      </FiltersRowHeader>
      <Select
        value={internalState.roomsMin}
        onChange={(event) => {
          const { value } = event.target;
          onChange(value);
        }}
      >
        {getIntegerRange(MAX_ROOMS).map((value) => (
          <option key={value} value={value}>{`${generalTexts.Misc.AtLeast} ${value} ${
            value > 1 ? generalTexts.PropertyDetails.RoomPlural.toLowerCase() : generalTexts.PropertyDetails.RoomSingular.toLowerCase()
          }`}</option>
        ))}
      </Select>
    </FiltersRow>
  );
};

export default RoomsFilter;
